import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

/**
 * @param children
 * @param location
 * @param description
 * @param title
 * @param image
 * @returns {JSX.Element}
 * @constructor
 */
const SEOTools = ({ children, location, description, title, image }) => {
    const { site, defaultOgImage } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                }
            }
            defaultOgImage: file(relativePath: { eq: "contact-section-bg.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 1200, layout: FIXED, formats: [PNG])
                }
            }
        }
    `)

    return (
        <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
            <html lang='fi' />
            <title>{title}</title>

            {/* Meta tags */}
            <meta name='viewport' content='width=device-width,initial-scale=1.0' />
            <meta charSet='utf-8' />
            <meta name='description' content={description || site.siteMetadata.description} />
            <meta name='keywords' content={site.siteMetadata.keywords.join(', ')} />
            {/* Open graph */}
            {location && <meta property='og:url' content={location.href} />}
            <meta property='og:image' content={image || getSrc(defaultOgImage.childImageSharp)} />
            <meta property='og:title' content={title} key='ogtitle' />
            <meta property='og:site_name' content={site.siteMetadata.title} key='ogsitename' />
            <meta property='og:description' content={description || site.siteMetadata.description} key='ogdesc' />

            {children}
        </Helmet>
    )
}

export default SEOTools
