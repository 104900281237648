import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import React from 'react'
import SelectorStyles from '../styles/SelectorStyles'
import NumberedRow from './Layout/NumberedRow'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const DemoSelector = () => (
    <SelectorStyles>
        <section className='py-5'>
            <Container>
                <NumberedRow number={1}>
                    <h2 className='h1 mb-3'>Valitse demo</h2>
                    <p className='mb-4'>
                        Loppukäyttäjän näkökulmasta havainnollistettu mobiilimaksumuuripalvelu. Tässä maksupalvelussa
                        tarvitset vain normaalin matkapuhelimen ja toimivan liittymän. Demossa havainnollistetaan myös
                        miten maksuton kokeilu (trial mode) tilauksen alussa toimii.
                    </p>

                    <div className='d-grid gap-2 d-md-block'>
                        <SelectorLink to={'/'} name='Maksa tilaus' />
                        <SelectorLink to={'/cancel'} name='Tilauksen peruutus' />
                    </div>
                </NumberedRow>
            </Container>
        </section>
    </SelectorStyles>
)

const SelectorLink = ({ to, name, disabled = false }) => (
    <div className='d-inline-block bg-light me-3 mb-2 shadow'>
        <Link
            to={to}
            className={`btn btn-outline-dark fs-6 rounded-0 spaced-light px-5 w-100 5 ${disabled && 'disabled'}`}>
            {name}
        </Link>
    </div>
)

export default DemoSelector
